<template>
  <b-modal 
    ref="modal" 
    id="group-status-modal"
    title="Téléverser un document"
    ok-title="Sauvegarder"
    cancel-variant="outline-secondary"
    cancel-title="Annuler"
    size="lg"
    @ok='submitFormData' >
      <b-form>

        <b-row>
         
          <b-col cols="6">
            <b-form-group label="Document à téléverser" label-for="v-c-password">
            <b-form-file
              placeholder=" Ajouter un document ici..."
              drop-placeholder="Ajouter un document ici..."
              @change="uploadDocumentToGroup"
            ></b-form-file>
            </b-form-group>
            <b-form-group
              label="Limiter à l'organisation"
              label-cols="10"
            >
              <b-form-checkbox
                v-model="document.is_org_only"
                class="mr-0 mt-50"
                name="is-rtl"
                switch
                inline
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Nom du document" label-for="document-name">
              <b-form-input
                v-model="document.name"
                name="document-name"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>

  </b-modal>
</template>
<script>
import {ValidationProvider, ValidationObserver,localize} from 'vee-validate'
import {required} from '@validations'
import {reactive, ref, watch} from "@vue/composition-api/dist/vue-composition-api";
import RequiredSelectBox from "@/shared/components/RequiredSelectBox";
import {useGroupApi} from "@/modules/group/composables/use-group-api";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import Vue from 'vue'
import {AUTH_STORE_NAMESPACE, AUTHENTICATED_USER_GETTER} from "@/modules/authnz/models/store";
import store from '@/store'

import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BModal,
  BFormFile
  // VBModal,
} from 'bootstrap-vue';
export default {
  name: "addGroupProductModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    RequiredSelectBox,
    BButton,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
    BInputGroup,
    BModal,
    BInputGroupPrepend,
    BRow
  },
  props: ['currentGroupId'],
  setup(props, {emit}) {
    const {uploadDocument, addDocumentToGroup, updateAccessGroupStatus} = useGroupApi();
    const {displaySuccessMessage, displayErrorMessage} = useToastNotification();

    const submitFormData = async () => {
      try {
        await addDocumentToGroup(props.currentGroupId, document);
        displaySuccessMessage('Le document a été téléversé avec succès');
        emit('monica-document-saved', {});
      } catch {
        displayErrorMessage("La sauvegarde du document a échoué");
      }
    };
    
    let documentIsUploading = ref(false)
    let document = reactive({
      is_org_only: false
    })
    let documentPreview = reactive({})
    let currentUser = store.getters[`${AUTH_STORE_NAMESPACE}/${AUTHENTICATED_USER_GETTER}`]

    const uploadDocumentToGroup = async (event) => {
      try{
        documentIsUploading.value = true;
        let file = event.target.files[0];

        if (file) {
          let data = new FormData();

          let type = file.type.split('/')
          data.append('name', file.name);
          data.append('type', type[0]);
          data.append('file', event.target.files[0]);
          
          let response = await uploadDocument(data)
          Vue.set(document, 'url', response.headers.location)

          document.size = file.size;
          document.type = getExtensionType(file.type);
          document.owner_id = currentUser.id
          document.group_id = props.currentGroupId

          documentIsUploading.value = false;

        }
      } catch (error) {
        documentIsUploading.value = false;
        console.error(error)
        displayErrorMessage("Le téléversement de l'image a échoué.");
      }

    }


    const submitGroupStatus = () => {
      emit('submitGroupStatus', {})
    }

    const getExtensionType = (longType) => {
      let mapping = {
        "application/pdf" : 'PDF',
        "text/csv" : 'CSV',
        "application/msword" : 'DOC',
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" : 'DOCX',
        "application/vnd.ms-excel" : 'XLS',
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : 'XLSX',
      }

      return mapping[longType];
    }
    return {
      document,
      uploadDocumentToGroup,
      submitFormData,
      documentPreview
    }
  },
  data() {
    return {
      required,
    }
  },
  mounted() {
  },
  methods: {
    validationForm() {
      this.$nextTick(() => {      
        this.$refs["modal"].hide()
      })
    },
  },
}

</script>

<style scoped>

</style>
